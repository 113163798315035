import {getInitialState} from './state';
import * as actionTypes from './actionTypes';

const getUserInfo = (state, action) => {
  const userInfo = action.payload;

  return Object.assign({}, state, {...userInfo});
};

function reducer(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.USER_INFO:
      return getUserInfo(state, action);
    default:
      return state;
  }
}

export default reducer;

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import configureStore from './redux';
import {ConfigProvider} from 'antd';
import App from './App';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'antd/dist/antd.css';
import './index.scss';
const store = configureStore();

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConfigProvider locale={zh_CN}>
        <App />
      </ConfigProvider>
    </Provider>,
    document.getElementById('root')
  );
};
renderApp();

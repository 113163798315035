import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import rootReducers from './reducers.js';

const combinRootReducers = combineReducers(rootReducers);

const configureStore = () => {
  const store = createStore(combinRootReducers, applyMiddleware(thunk));
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    // module.hot.accept('./reducers.js', () => {
    const nextReducer = combinRootReducers;
    store.replaceReducer(nextReducer);
    // });
  }
  return store;
};
export default configureStore;

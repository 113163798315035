import {lazy} from 'react';
const Home = lazy(() => import('@/containers/home'));
const ProductService = lazy(() => import('@/containers/productService'));
const About = lazy(() => import('@/containers/about'));
const Case = lazy(() => import('@/containers/case'));
const News = lazy(() => import('@/containers/news'));
const BaiGuoYuan = lazy(() => import('@/containers/newsCont/baiguoyuan'));
const Luck = lazy(() => import('@/containers/newsCont/luck'));
const Task = lazy(() => import('@/containers/newsCont/task'));
const BigBrother = lazy(() => import('@/containers/newsCont/bigBrother'));
const Children = lazy(() => import('@/containers/newsCont/children'));
const QiWei = lazy(() => import('@/containers/newsCont/qiwei'));
const BizChat = lazy(() => import('@/containers/newsCont/bizChat'));
const Operation = lazy(() => import('@/containers/newsCont/operation'));
const ChartTool = lazy(() => import('@/containers/newsCont/chartTool'));
const OpenCourse = lazy(() => import('@/containers/newsCont/openCourse'));
const TxTogether = lazy(() => import('@/containers/newsCont/txTogether'));
const WxCustomer = lazy(() => import('@/containers/newsCont/wxCustomer'));
const ChartMarketing = lazy(() => import('@/containers/newsCont/chartMarketing'));
export default [
  {path: '/home', name: 'Home', component: Home},
  {path: '/productService', name: 'ProductService', component: ProductService},
  {path: '/about', name: 'About', component: About},
  {path: '/case/:id', name: 'Case', component: Case},
  {path: '/news', name: 'News', component: News},
  {path: '/baiguoyuan', name: 'BaiGuoYuan', component: BaiGuoYuan},
  {path: '/luck', name: 'Luck', component: Luck},
  {path: '/task', name: 'Task', component: Task},
  {path: '/children', name: 'Children', component: Children},
  {path: '/bigBrother', name: 'BigBrother', component: BigBrother},
  {path: '/qiWei', name: 'QiWei', component: QiWei},
  {path: '/bizChat', name: 'BizChat', component: BizChat},
  {path: '/operation', name: 'Operation', component: Operation},
  {path: '/chartTool', name: 'ChartTool', component: ChartTool},
  {path: '/openCourse', name: 'OpenCourse', component: OpenCourse},
  {path: '/txTogether', name: 'TxTogether', component: TxTogether},
  {path: '/wxCustomer', name: 'WxCustomer', component: WxCustomer},
  {path: '/chartMarketing', name: 'ChartMarketing', component: ChartMarketing}
];

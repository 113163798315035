import React, {Component, Suspense} from 'react';
import {HashRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {Spin} from 'antd';
import Routers from './routerMap';

console.log(Routers, 'Routers')
class Root extends Component {
  render() {
    return (
      <Router>
        <Suspense
          fallback={
            <div className="page-loading">
              <Spin size="large" />
            </div>
          }
        >
          <Switch>
            {Routers.map((item, index) => {
              return (
                <Route
                  key={index}
                  exact={item.exact}
                  path={item.path}
                  component={item.component}
                />
              );
            })}
            <Redirect to="/home" />
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default Root;
